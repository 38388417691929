@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background: #242424;
  padding: 0;
  margin: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: "Rubik", sans-serif;
}

.geohash-cell-label {
  font-family: "Jim Nightshade", cursive;
}

.leaflet-popup-content-wrapper {
  background: #111;
}

.parallax-marker {
  font-family: "Jim Nightshade", cursive;
  pointer-events: none;
  opacity: 0.75;
  font-weight: bold;
}

/* ---------- Fog ---------- */
.fogwrapper {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
  filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
  z-index: 30;
  overflow: hidden;
  pointer-events: none;
}
#foglayer_01,
#foglayer_02,
#foglayer_03 {
  height: 100%;
  position: absolute;
  width: 200%;
}
#foglayer_01 .image01,
#foglayer_01 .image02,
#foglayer_02 .image01,
#foglayer_02 .image02,
#foglayer_03 .image01,
#foglayer_03 .image02 {
  float: left;
  height: 100%;
  width: 50%;
}
#foglayer_01 {
  -webkit-animation: foglayer_01_opacity 10s linear infinite,
    foglayer_moveme 15s linear infinite;
  -moz-animation: foglayer_01_opacity 10s linear infinite,
    foglayer_moveme 15s linear infinite;
  animation: foglayer_01_opacity 10s linear infinite,
    foglayer_moveme 15s linear infinite;
}
#foglayer_02,
#foglayer_03 {
  -webkit-animation: foglayer_02_opacity 21s linear infinite,
    foglayer_moveme 13s linear infinite;
  -moz-animation: foglayer_02_opacity 21s linear infinite,
    foglayer_moveme 13s linear infinite;
  animation: foglayer_02_opacity 21s linear infinite,
    foglayer_moveme 13s linear infinite;
}

/* ---------- Moving Fog ---------- */
/*
  'size: cover' || 'size: 100%'; results remain the same
  'attachment: scroll' can be added or removed; results remain the same
  'attachment: fixed' causing unexpected results in Chrome
  'repeat-x' || 'no-repeat'; results remain the same
*/
#foglayer_01 .image01,
#foglayer_01 .image02 {
  background: url("https://raw.githubusercontent.com/danielstuart14/CSS_FOG_ANIMATION/master/fog1.png")
    center center/cover no-repeat transparent;
}
#foglayer_02 .image01,
#foglayer_02 .image02,
#foglayer_03 .image01,
#foglayer_03 .image02 {
  background: url("https://raw.githubusercontent.com/danielstuart14/CSS_FOG_ANIMATION/master/fog2.png")
    center center/cover no-repeat transparent;
}

/* ---------- Keyframe Layer 1 ---------- */
@-webkit-keyframes foglayer_01_opacity {
  0% {
    opacity: 0.1;
  }
  22% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.28;
  }
  58% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.16;
  }
  100% {
    opacity: 0.1;
  }
}
@-moz-keyframes foglayer_01_opacity {
  0% {
    opacity: 0.1;
  }
  22% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.28;
  }
  58% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.16;
  }
  100% {
    opacity: 0.1;
  }
}
@-o-keyframes foglayer_01_opacity {
  0% {
    opacity: 0.1;
  }
  22% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.28;
  }
  58% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.16;
  }
  100% {
    opacity: 0.1;
  }
}
@keyframes foglayer_01_opacity {
  0% {
    opacity: 0.1;
  }
  22% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.28;
  }
  58% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.16;
  }
  100% {
    opacity: 0.1;
  }
}
/* ---------- Keyframe Layer 2 ---------- */
@-webkit-keyframes foglayer_02_opacity {
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.1;
  }
  80% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.5;
  }
}
@-moz-keyframes foglayer_02_opacity {
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.1;
  }
  80% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.5;
  }
}
@-o-keyframes foglayer_02_opacity {
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.1;
  }
  80% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes foglayer_02_opacity {
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.1;
  }
  80% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.5;
  }
}
/* ---------- Keyframe Layer 3 ---------- */
@-webkit-keyframes foglayer_03_opacity {
  0% {
    opacity: 0.8;
  }
  27% {
    opacity: 0.2;
  }
  52% {
    opacity: 0.6;
  }
  68% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}
@-moz-keyframes foglayer_03_opacity {
  0% {
    opacity: 0.8;
  }
  27% {
    opacity: 0.2;
  }
  52% {
    opacity: 0.6;
  }
  68% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}
@-o-keyframes foglayer_03_opacity {
  0% {
    opacity: 0.8;
  }
  27% {
    opacity: 0.2;
  }
  52% {
    opacity: 0.6;
  }
  68% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}
@keyframes foglayer_03_opacity {
  0% {
    opacity: 0.8;
  }
  27% {
    opacity: 0.2;
  }
  52% {
    opacity: 0.6;
  }
  68% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}
/* ---------- Keyframe moveMe ---------- */
@-webkit-keyframes foglayer_moveme {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@-moz-keyframes foglayer_moveme {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@-o-keyframes foglayer_moveme {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@keyframes foglayer_moveme {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

@media only screen and (min-width: 280px) and (max-width: 767px) {
  #foglayer_01 .image01,
  #foglayer_01 .image02,
  #foglayer_02 .image01,
  #foglayer_02 .image02,
  #foglayer_03 .image01,
  #foglayer_03 .image02 {
    width: 100%;
  }
}
